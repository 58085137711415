import { ref, computed, Ref, ComputedRef, watch } from "@vue/composition-api";
import { IBody } from "../services/interfaces/IUtil";
import { BvTableFieldArray } from "bootstrap-vue";
import { Timestamp } from "firebase/firestore";

export interface ITableConfig {
  perPage?: number;
  currentPage?: number;
  perPageOptions?: number[];
  sortBy: string;
  isSortDirDesc?: boolean;
  customFilter?: (item: any) => boolean;
  filters?: any;
}

export interface IFilterOptions {
  perPage: number;
  currentPage: number;
  searchQuery: string;
  sortyBy: string;
  isSortDirDesc: boolean;
  filters: any;
}

export interface IRefreshTable {
  data: Array<any>;
  total: number;
  display: number;
}

export default class useTable {
  // ------------------------------------------------
  // Table List
  // ------------------------------------------------
  listTable: Ref<Array<any>> = ref([]);

  // @ts-ignore
  fetchRefresh: (options: IFilterOptions) => Promise<IRefreshTable>;

  // Table Handlers
  tableColumns: BvTableFieldArray = [];

  config: ITableConfig = {} as ITableConfig;

  perPage = ref(10);
  total = ref(0);
  display = ref(0);
  currentPage = ref(1);
  perPageOptions = [10, 25, 50, 100];
  searchQuery = ref("");
  sortBy = ref("name");
  isSortDirDesc = ref(false);
  filters = ref({} as any);
  loading = ref(true);
  firstRequest = ref(true);
  filterOn: Ref<Array<any>> = ref([]);
  filtering: boolean = false;
  customFilter?: (item: any) => boolean = undefined;

  constructor(
    columns: BvTableFieldArray,
    overrideConfig: ITableConfig,
    fetchRefresh?: (options: IFilterOptions) => Promise<IRefreshTable>
  ) {
    this.tableColumns = columns;
    this.sortBy.value = overrideConfig.sortBy;

    if (fetchRefresh) this.fetchRefresh = fetchRefresh;

    if (overrideConfig.perPage) this.perPage.value = overrideConfig.perPage;
    if (overrideConfig.currentPage)
      this.currentPage.value = overrideConfig.currentPage;
    if (overrideConfig.perPageOptions)
      this.perPageOptions = overrideConfig.perPageOptions;
    if (overrideConfig.isSortDirDesc)
      this.isSortDirDesc.value = overrideConfig.isSortDirDesc;
    if (overrideConfig.customFilter)
      this.customFilter = overrideConfig.customFilter;
    if (overrideConfig.filters) this.filters.value = overrideConfig.filters;

    this.config = overrideConfig;
  }

  // Computeds
  dataMeta: ComputedRef<{
    from: number;
    to: number;
    of: number;
    total: number;
  }> = computed(() => {
    const localItemsCount = this.listTable.value
      ? this.listTable.value.length
      : 0;
    return {
      from:
        this.perPage.value * (this.currentPage.value - 1) +
        (localItemsCount ? 1 : 0),
      to: Math.min(
        this.perPage.value * this.currentPage.value,
        this.total.value
      ),
      of: this.display.value,
      total: this.total.value,
    };
  });

  // Watchs
  watchData = watch(
    [
      this.currentPage,
      this.perPage,
      this.searchQuery,
      this.sortBy,
      this.isSortDirDesc,
    ],
    () => {
      if (this.sortBy.value == "") {
        this.sortBy.value = this.config.sortBy;
      }

      this.filterItems();
    }
  );

  // Methods
  /**
   * Essa função substituí a páginação padrão do b-table, acontece que a b-table
   * quando tem muitos registros ela apresenta lentidão na sua filtragem padrão,
   * quando temos uma tabela com muitos elementos para serem filtrados sem serverSide
   * podemos utilizar essa filterItems para contornar a lentidão da b-table.
   */
  filterItems() {
    this.filtering = true;

    // Aplica a filtragem padrão com base na pesquisa e na página atual
    let filteredItems = this.listTable.value.filter((item) => {
      let match = true;
      if (this.searchQuery.value != "") {
        const filters = Object.entries(item).filter(([key, value]) => {
          const strValue = "" + value;

          return strValue
            .toLowerCase()
            .includes(this.searchQuery.value.toLowerCase());
        });

        if (filters.length == 0) match = false;
      }

      // Retorna verdadeiro para incluir o item na lista filtrada
      return match;
    });

    // Aplica o filtro personalizado, se fornecido
    if (this.customFilter) {
      filteredItems = filteredItems.filter(this.customFilter);
    }

    filteredItems.sort((a: any, b: any) => {
      const compareString = (
        itemA: string,
        itemB: string,
        sortDesc: boolean
      ): number => {
        if (!sortDesc) {
          return itemA.localeCompare(itemB);
        }

        return itemB.localeCompare(itemA);
      };

      const compareNumber = (
        itemA: number,
        itemB: number,
        sortDesc: boolean
      ): number => {
        if (!sortDesc) {
          return itemA - itemB;
        }

        return itemB - itemA;
      };

      const compareBoolean = (
        itemA: boolean,
        itemB: boolean,
        sortDesc: boolean
      ): number => {
        let responseA = -1;
        let responseB = 1;

        if (sortDesc) {
          responseA = 1;
          responseB = -1;
        }

        if (itemA && !itemB) {
          return responseA; // 'a' vem antes de 'b'
        }
        if (!itemA && itemB) {
          return responseB; // 'b' vem antes de 'a'
        }

        return 0; // mantém a ordem atual
      };

      const compareTimestamp = (
        a: Timestamp,
        b: Timestamp,
        sortDesc: boolean
      ): number => {
        if (!sortDesc) {
          return a.toMillis() - b.toMillis();
        }

        return b.toMillis() - a.toMillis();
      };

      if (
        a[this.sortBy.value] === null ||
        typeof a[this.sortBy.value] == "undefined"
      ) {
        if (
          b[this.sortBy.value] === null ||
          typeof b[this.sortBy.value] == "undefined"
        ) {
          return 0; // Mantém a ordem
        } else if (this.isSortDirDesc.value) {
          return 0; // Mantém a ordem
        } else {
          return 1; // 'b' vem antes de 'a'
        }
      } else if (
        b[this.sortBy.value] === null ||
        typeof b[this.sortBy.value] == "undefined"
      ) {
        if (
          a[this.sortBy.value] === null ||
          typeof a[this.sortBy.value] == "undefined"
        ) {
          return 0; // Mantém a ordem
        } else if (this.isSortDirDesc.value) {
          return 1; // 'b' vem antes de 'a'
        } else {
          return 0; // Mantém a ordem
        }
      } else if (a[this.sortBy.value] instanceof Timestamp) {
        return compareTimestamp(
          a[this.sortBy.value],
          b[this.sortBy.value],
          this.isSortDirDesc.value
        );
      } else {
        switch (typeof a[this.sortBy.value]) {
          case "boolean":
            return compareBoolean(
              a[this.sortBy.value],
              b[this.sortBy.value],
              this.isSortDirDesc.value
            );
          case "number":
            return compareNumber(
              a[this.sortBy.value],
              b[this.sortBy.value],
              this.isSortDirDesc.value
            );
          default:
            return compareString(
              a[this.sortBy.value],
              b[this.sortBy.value],
              this.isSortDirDesc.value
            );
        }
      }
    });

    // Calcula o índice inicial e final dos itens na página atual
    const startIndex = (this.currentPage.value - 1) * this.perPage.value;
    const endIndex = startIndex + this.perPage.value;

    // Retorna apenas os itens da página atual
    this.total.value = this.listTable.value.length;
    this.display.value = filteredItems.length;
    this.filterOn.value = filteredItems.slice(startIndex, endIndex);
    this.filtering = false;
  }

  mapFilterToBody(options: IFilterOptions) {
    let body: IBody = {
      paginate: true,
      draw: options.currentPage,
      length: options.perPage,
    };

    if (options.searchQuery) {
      body.search = { value: options.searchQuery };
    }

    if (options.sortyBy) {
      body.columns = this.tableColumns
        .filter((column) => {
          if (typeof column != "string") return column.sortable;
        })
        .map((column): { data: string } => {
          if (typeof column != "string") return { data: column.key };
          else return { data: column };
        });

      body.order = [
        {
          column: body.columns.findIndex(
            (column) => column.data == options.sortyBy
          ),
          dir: options.isSortDirDesc ? "desc" : "asc",
        },
      ];
    }

    return body;
  }

  async fetchList(callback: Function) {
    this.loading.value = true;
    await this.fetchRefresh({
      perPage: this.perPage.value,
      currentPage: this.currentPage.value,
      searchQuery: this.searchQuery.value,
      sortyBy: this.sortBy.value,
      isSortDirDesc: this.isSortDirDesc.value,
      filters: this.filters.value,
    })
      .then((response: IRefreshTable) => {
        this.listTable.value = response.data;
        this.total.value = response.total;
        this.display.value = response.display;
        callback(this.listTable.value);
      })
      .finally(() => {
        this.loading.value = false;
        this.firstRequest.value = false;
      });
  }
}
